function backToTop() {
  var backToTopButton = document.getElementById("back-to-top");

  window.addEventListener("scroll", function () {
    if (window.scrollY > 300) {
      backToTopButton.classList.add("show");
    } else {
      backToTopButton.classList.remove("show");
    }
  });

  backToTopButton.addEventListener("click", function (e) {
    e.preventDefault();
    window.scrollTo({ top: 0, behavior: "smooth" });
  });
}

function faq() {
  const faqTitles = document.querySelectorAll(".faq-titolo");
  const faqContents = document.querySelectorAll(".faq-content");

  // Close all FAQ contents initially except the first one
  faqContents.forEach((content, index) => {
    if (index !== 0) {
      content.style.display = "none";
    } else {
      content.classList.add("visible");
      content.previousElementSibling
        .querySelector(".chevron")
        .classList.add("up");
    }
  });

  // Add click event listener to each FAQ title
  faqTitles.forEach((title) => {
    title.addEventListener("click", function () {
      // Close all FAQ contents
      faqContents.forEach((content) => {
        content.style.display = "none";
        content.classList.remove("visible");
        content.previousElementSibling
          .querySelector(".chevron")
          .classList.remove("up");
      });

      // Open the clicked FAQ content
      const content = this.nextElementSibling;
      if (content.style.display === "none") {
        content.style.display = "block";
        content.classList.add("visible");
        this.querySelector(".chevron").classList.add("up");
      } else {
        content.style.display = "none";
        content.classList.remove("visible");
        this.querySelector(".chevron").classList.remove("up");
      }
    });
  });
}

function lightboxVenobox() {
  new VenoBox({
    selector: ".venobox",
    numeration: true,
    infinigall: true,
    share: false,
    spinner: "bounce",
    titleattr: "data-title",
  });
}

document.addEventListener("DOMContentLoaded", function () {
  backToTop();
  faq();
  lightboxVenobox();
});
